



























































import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'
import ImageMixin from '@/components/modules/drillsv2/molecules/ImageMixin'
import ScrollGuide from '@/components/modules/drills/atoms/ScrollGuide.vue'
import PenCanvas from '@/components/modules/drills/atoms/PenCanvas.vue'
import { Debounce } from 'vue-debounce-decorator'
import _ from 'lodash'
import TooltipButton from '@/components/modules/drills/atoms/TooltipButton.vue'
import DrillWebApi from '@/mixins/drillsv2/WebApi'

@Component({
  components: {
    ScrollGuide,
    PenCanvas,
    TooltipButton,
  },
})
export default class ImageHorizontal extends Mixins(ImageMixin, DrillWebApi) {
  // 読み込み完了フラグ
  private loadComplete = false

  @Prop({ default: 0 })
  pageIndex!: number

  @Prop({ default: 1 })
  pageNum!: number

  private canvasNameRight = 'canvasRight'
  private canvasNameLeft = 'canvasLeft'

  // s3アクセス用フルURL
  private dokkaiUrl: string[] = []
  private mondaiUrl: string[] = []
  private sijiUrl: string | null = null

  private get hasDokkai(): boolean {
    return this.dokkai(this.pageIndex).length > 0
  }

  private handleResize = _.throttle(this.changeCanvasSize, 500)

  private async mounted() {
    Vue.prototype.$loading.startWhite()
    // s3アクセス用URL取得
    this.dokkaiUrl = await Promise.all(
      this.dokkai(this.pageIndex).map(async (path) => {
        return await this.getImageUrl(path)
      })
    )
    this.mondaiUrl = await Promise.all(
      this.mondai(this.pageIndex).map(async (path) => {
        return await this.getImageUrl(path)
      })
    )
    const siji = this.siji(this.pageIndex)
    if (siji) {
      this.sijiUrl = await this.getImageUrl(siji)
    }

    window.addEventListener('resize', this.handleResize)
    this.loadComplete = true
    Vue.prototype.$loading.complete()
  }

  /**
   * ページ番号を算出して返す
   */
  private get pageNumber(): number {
    return this.currentPageNumber || this.pageNum
  }

  private beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  }

  @Debounce(300)
  private changeCanvasSize(): void {
    const leftBlock = this.$refs['left-block'] as HTMLElement
    const rightBlock = this.$refs['right-block'] as HTMLElement

    // canvasサイズ設定
    this.setCanvasSize(Vue.prototype.$penCanvases[this.canvasNameLeft], this.calculateCanvasSize(leftBlock))
    this.setCanvasSize(Vue.prototype.$penCanvases[this.canvasNameRight], this.calculateCanvasSize(rightBlock))
  }

  private calculateCanvasSize(element: HTMLElement): any {
    if (!element) return { width: 0, height: 0 }

    return { width: element.clientWidth, height: element.scrollHeight }
  }

  private setCanvasSize(canvas: any, size: { width: number; height: number }): void {
    if (!canvas) return

    canvas.changeSize({
      width: size.width,
      height: size.height,
    })
    canvas.setCanvasContext()
  }
}
