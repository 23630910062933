





import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Title extends Vue {
  private title = ''

  private async mounted() {
    Vue.prototype.$logger.info('-- Title V2 mounted')
    // タイトル取得APIを呼ぶ
    try {
      const res = await Vue.prototype.$http.httpWithToken.get(
        `/v2/drills/title?resultDrillId=${Number(this.$route.query.resultDrillId)}`
      )
      this.title = res.data.title
      this.$store.commit('drillsV3/setTitleResult', res.data.title)

    } catch {
      throw 'Drill Error!'
    }
  }
}
